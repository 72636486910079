/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import xl from 'excel4node';
// @ts-ignore
import FileSaver from 'file-saver';

const options = {
  sheetView: {
    showGridLines: false,
  },
};

export async function create(
  sheetData: any,
  additional_data: any,
): Promise<void> {
  /// /////////// PLANILHA DE CUSTO

  const { client, month, year, taxaImposto } = additional_data;

  const wb = new xl.Workbook();
  const ws = wb.addWorksheet('Analítico - Custo', options);

  const styles = wb.createStyle({
    font: {
      name: 'arial',
      bold: true,
      size: 20,
    },
    border: {
      bottom: {
        style: 'thin',
        color: '#000000',
      },
      outline: true,
    },
    alignment: {
      // §18.8.1
      horizontal: 'center',
      justifyLastLine: true,
      vertical: 'center',
    },
  });

  const headersStyle = wb.createStyle({
    font: {
      name: 'arial',
      bold: true,
      size: 11,
    },
    fill: {
      type: 'pattern',
      patternType: 'solid',
      bgColor: '#d9d9d9',
      fgColor: '#d9d9d9',
    },
    alignment: {
      // §18.8.1
      horizontal: 'left',
      justifyLastLine: true,
      vertical: 'center',
    },
  });

  const itemStyle = wb.createStyle({
    font: {
      name: 'arial',
      bold: true,
      size: 11,
      underline: true,
    },
    fill: {
      type: 'pattern',
      patternType: 'solid',
      bgColor: '#f2f2f2',
      fgColor: '#f2f2f2',
    },
    alignment: {
      // §18.8.1
      horizontal: 'left',
      justifyLastLine: true,
      vertical: 'center',
    },
  });

  const othersStyle = wb.createStyle({
    font: {
      name: 'arial',
      bold: true,
      size: 11,
      underline: true,
    },
    fill: {
      type: 'pattern',
      patternType: 'solid',
      bgColor: '#f2f2f2',
      fgColor: '#f2f2f2',
    },
    alignment: {
      // §18.8.1
      horizontal: 'center',
      justifyLastLine: true,
      vertical: 'center',
    },
  });

  const novoEstilo = wb.createStyle({
    font: {
      name: 'arial',
      bold: true,
      size: 11,
      underline: true,
    },
    alignment: {
      // §18.8.1
      horizontal: 'center',
      justifyLastLine: true,
      vertical: 'center',
    },
  });

  const subItemStyle = wb.createStyle({
    font: {
      name: 'arial',
      size: 11,
    },
    alignment: {
      horizontal: 'left',
      justifyLastLine: true,
      vertical: 'center',
    },
  });

  const otherSubItemStyle = wb.createStyle({
    font: {
      name: 'arial',
      size: 11,
    },
    alignment: {
      horizontal: 'center',
      justifyLastLine: true,
      vertical: 'center',
    },
  });

  const totalSubItemStyle = wb.createStyle({
    font: {
      name: 'arial',
      bold: true,
      size: 11,
      underline: true,
    },
    fill: {
      type: 'pattern',
      patternType: 'solid',
      bgColor: '#f2f2f2',
      fgColor: '#f2f2f2',
    },
    alignment: {
      // §18.8.1
      horizontal: 'left',
      justifyLastLine: true,
      vertical: 'center',
    },
    border: {
      top: {
        style: 'thin', // §18.18.3 ST_BorderStyle (Border Line Styles) ['none', 'thin', 'medium', 'dashed', 'dotted', 'thick', 'double', 'hair', 'mediumDashed', 'dashDot', 'mediumDashDot', 'dashDotDot', 'mediumDashDotDot', 'slantDashDot']
        color: '#000000',
      },
      bottom: {
        style: 'double', // §18.18.3 ST_BorderStyle (Border Line Styles) ['none', 'thin', 'medium', 'dashed', 'dotted', 'thick', 'double', 'hair', 'mediumDashed', 'dashDot', 'mediumDashDot', 'dashDotDot', 'mediumDashDotDot', 'slantDashDot']
        color: '#000000',
      },
      outline: true,
    },
  });

  const otherSubItemStyleRight = wb.createStyle({
    font: {
      name: 'arial',
      size: 11,
    },
    alignment: {
      horizontal: 'right',
      justifyLastLine: true,
      vertical: 'center',
    },
  });

  const totalSubItemStyleRight = wb.createStyle({
    font: {
      name: 'arial',
      bold: true,
      size: 11,
    },
    fill: {
      type: 'pattern',
      patternType: 'solid',
      bgColor: '#f2f2f2',
      fgColor: '#f2f2f2',
    },
    alignment: {
      horizontal: 'right',
      justifyLastLine: true,
      vertical: 'center',
    },
    border: {
      top: {
        style: 'thin', // §18.18.3 ST_BorderStyle (Border Line Styles) ['none', 'thin', 'medium', 'dashed', 'dotted', 'thick', 'double', 'hair', 'mediumDashed', 'dashDot', 'mediumDashDot', 'dashDotDot', 'mediumDashDotDot', 'slantDashDot']
        color: '#000000',
      },
      bottom: {
        style: 'double', // §18.18.3 ST_BorderStyle (Border Line Styles) ['none', 'thin', 'medium', 'dashed', 'dotted', 'thick', 'double', 'hair', 'mediumDashed', 'dashDot', 'mediumDashDot', 'dashDotDot', 'mediumDashDotDot', 'slantDashDot']
        color: '#000000',
      },
      outline: true,
    },
  });

  const faturamentoFillStyle = wb.createStyle({
    fill: {
      type: 'pattern',
      patternType: 'solid',
      bgColor: '#f2f2f2',
      fgColor: '#f2f2f2',
    },
    font: {
      name: 'arial',
      size: 14,
    },
  });

  // Heigth e With de linas e colunas
  ws.row(1).setHeight(9);
  ws.row(2).setHeight(5);
  ws.row(3).setHeight(30);
  ws.row(5).setHeight(5);
  ws.row(7).setHeight(5);
  ws.row(9).setHeight(5);

  // receitas qesh store
  for (let i = 5; i <= 16; i += 1) ws.row(i).hide();

  // qr code e debito em conta
  // p2p

  // taxas galera
  ws.cell(16, 1).number(0.018);
  ws.cell(16, 2).number(0.9);

  ws.row(11).setHeight(2); // Despesas e processamento
  ws.row(17).setHeight(5);
  ws.row(19).setHeight(5);

  ws.column(1).setWidth(4);
  ws.column(2).setWidth(2);
  ws.column(3).setWidth(45);
  ws.column(4).setWidth(30);
  ws.column(5).setWidth(10);
  ws.column(6).setWidth(8);
  ws.column(7).setWidth(2);

  ws.column(8).hide();
  ws.column(9).hide();
  ws.column(10).hide();
  ws.column(11).hide();
  ws.column(12).hide();
  ws.column(13).hide();
  ws.column(14).hide();
  ws.column(15).hide();
  ws.column(16).hide();
  ws.column(17).hide();
  ws.column(18).hide();

  ws.column(19).setWidth(2);
  ws.column(20).setWidth(8);
  ws.column(21).setWidth(11);
  ws.column(22).setWidth(15);

  ws.column(23).setWidth(20);

  // Cabeçalho inicial
  ws.cell(3, 3).string(`Custo BaaS | ${client.name}`).style(styles);

  ws.cell(3, 4).string(`${month}/${year}`).style(styles);

  ws.cell(3, 5).style(styles);

  ws.cell(3, 6).style(styles);

  ws.cell(3, 20).style(styles);

  ws.cell(3, 21).style(styles);

  ws.cell(3, 22).style(styles);

  ws.cell(3, 23).style(styles);

  ws.cell(4, 22).string('Custo').style(novoEstilo);

  // Receitas
  ws.cell(6, 3).string('Receitas Qesh Store');

  ws.cell(12, 20).style(otherSubItemStyle);
  ws.cell(12, 21).style(otherSubItemStyle);
  ws.cell(12, 23)
    .style(otherSubItemStyleRight)
    .style({ numberFormat: '#,##0.00; -#,##0.00; -' });

  ws.cell(13, 20).style(otherSubItemStyle);
  ws.cell(13, 21).style(otherSubItemStyle);
  ws.cell(13, 23)
    .style(otherSubItemStyleRight)
    .style({ numberFormat: '#,##0.00; -#,##0.00; -' });

  ws.cell(14, 20).style(otherSubItemStyle);
  ws.cell(14, 21).style(otherSubItemStyle);
  ws.cell(14, 23)
    .style(otherSubItemStyleRight)
    .style({ numberFormat: '#,##0.00; -#,##0.00; -' });

  ws.cell(15, 20)
    .style(otherSubItemStyle)
    .style({ numberFormat: '#,##0.00; -#,##0.00; -' });
  ws.cell(15, 21).style(otherSubItemStyle);
  ws.cell(15, 23)
    .style(otherSubItemStyleRight)
    .style({ numberFormat: '#,##0.00; -#,##0.00; -' });

  ws.cell(16, 20).style(totalSubItemStyleRight);
  ws.cell(16, 21).style(totalSubItemStyleRight);
  ws.cell(16, 23)
    .style(totalSubItemStyleRight)
    .style({ numberFormat: '#,##0.00; -#,##0.00; -' });

  // Conta de pagamento
  ws.cell(18, 3).string('Serviços operacionais').style(headersStyle);
  ws.cell(18, 4).style(headersStyle);
  ws.cell(18, 5).style(headersStyle);
  ws.cell(18, 6).style(headersStyle);

  ws.cell(18, 20).style(headersStyle);
  ws.cell(18, 21).style(headersStyle);
  ws.cell(18, 22).style(headersStyle);
  ws.cell(18, 23).style(headersStyle);

  ws.cell(20, 3).string('Item').style(itemStyle);
  ws.cell(20, 4).string('Métrica').style(othersStyle);
  ws.cell(20, 5).string('Imposto pós').style(othersStyle);
  ws.cell(20, 6)
    .number(taxaImposto)
    // .style({ numberFormat: '#0.00%; -#0.00%; -' })
    .style(othersStyle);

  ws.cell(20, 20).string('Preço').style(othersStyle);
  ws.cell(20, 21).string('Quantidade').style(othersStyle);
  ws.cell(20, 22).string('Valor').style(othersStyle);
  ws.cell(20, 23).string('Total (em R$)').style(othersStyle);

  // items da seção

  const inicioCustosOperacionais = 21;
  let currentLine = inicioCustosOperacionais;

  for (let i = 0; i < sheetData.operational.length; i += 1) {
    const item = sheetData.operational[i];
    ws.cell(currentLine, 3).string(item.description).style(subItemStyle);
    ws.cell(currentLine, 4).string(item.metric).style(otherSubItemStyle);
    ws.cell(currentLine, 5)
      .number(item.taxes_amount ? 1 : 0)
      .style(otherSubItemStyle)
      .style({ numberFormat: '"Sim";;"Não"' });
    ws.cell(currentLine, 6).style(otherSubItemStyle);
    // Segunda coluna
    ws.cell(currentLine, 20)
      .number(+item.amount || 0)
      .style(otherSubItemStyle)
      .style({ numberFormat: '#,##0.00; -#,##0.00; -' });
    ws.cell(currentLine, 21)
      .number(+item.quantity || 0)
      .style(otherSubItemStyle);
    ws.cell(currentLine, 22)
      .number(0)
      .style(otherSubItemStyle)
      .style({ numberFormat: '#,##0.00; -#,##0.00; -' });
    ws.cell(currentLine, 23)
      .formula(
        `IF($E${currentLine}=1;(U${currentLine}*T${currentLine}/(1-(F20/100)));(U${currentLine}*T${currentLine}))`,
      )
      .style(otherSubItemStyleRight)
      .style({ numberFormat: '#,##0.00; -#,##0.00; -' });
    currentLine += 1;
  }

  ws.cell(currentLine, 3).string('Total').style(totalSubItemStyle);
  ws.cell(currentLine, 4).style(totalSubItemStyle);
  ws.cell(currentLine, 5).style(totalSubItemStyle);
  ws.cell(currentLine, 6).style(totalSubItemStyle);
  // Segunda coluna
  ws.cell(currentLine, 20).style(totalSubItemStyleRight);
  ws.cell(currentLine, 21).style(totalSubItemStyleRight);
  ws.cell(currentLine, 22).style(totalSubItemStyleRight);
  ws.cell(currentLine, 23)
    .formula(`SUM(W21:W${currentLine - 1})`)
    .style(totalSubItemStyleRight)
    .style({ numberFormat: '#,##0.00; -#,##0.00; -' });

  const inicioServicosFinanceiros = currentLine + 2;
  currentLine = inicioServicosFinanceiros;

  // //Serviços financeiros
  ws.cell(inicioServicosFinanceiros, 3)
    .string('Serviços Financeiros')
    .style(headersStyle);
  ws.cell(inicioServicosFinanceiros, 4).style(headersStyle);
  ws.cell(inicioServicosFinanceiros, 5).style(headersStyle);
  ws.cell(inicioServicosFinanceiros, 6).style(headersStyle);

  ws.cell(inicioServicosFinanceiros, 20).style(headersStyle);
  ws.cell(inicioServicosFinanceiros, 21).style(headersStyle);
  ws.cell(inicioServicosFinanceiros, 22).style(headersStyle);
  ws.cell(inicioServicosFinanceiros, 23).style(headersStyle);

  ws.row(inicioServicosFinanceiros + 1).setHeight(5);

  ws.cell(inicioServicosFinanceiros + 2, 3)
    .string('Item')
    .style(itemStyle);
  ws.cell(inicioServicosFinanceiros + 2, 4)
    .string('Métrica')
    .style(othersStyle);
  ws.cell(inicioServicosFinanceiros + 2, 5)
    .string('Imposto pós')
    .style(othersStyle);
  ws.cell(inicioServicosFinanceiros + 2, 6).style(othersStyle);

  ws.cell(inicioServicosFinanceiros + 2, 20)
    .string('Preço')
    .style(othersStyle);
  ws.cell(inicioServicosFinanceiros + 2, 21)
    .string('Quantidade')
    .style(othersStyle);
  ws.cell(inicioServicosFinanceiros + 2, 22)
    .string('Valor')
    .style(othersStyle);
  ws.cell(inicioServicosFinanceiros + 2, 23)
    .string('Total (em R$)')
    .style(othersStyle);

  currentLine += 3;

  for (let i = 0; i < sheetData.financial.length; i += 1) {
    const item = sheetData.financial[i];
    ws.cell(currentLine, 3).string(item.description).style(subItemStyle);
    ws.cell(currentLine, 4).string(item.metric).style(otherSubItemStyle);
    ws.cell(currentLine, 5)
      .number(item.taxes_amount ? 1 : 0)
      .style(otherSubItemStyle)
      .style({ numberFormat: '"Sim";;"Não"' });
    ws.cell(currentLine, 6).style(otherSubItemStyle);
    ws.cell(currentLine, 20)
      .number(+item.amount || 0)
      .style(otherSubItemStyle)
      .style(
        item.percent
          ? { numberFormat: '#0.00%; -#0.00%; -' }
          : { numberFormat: '#,##0.00; -#,##0.00; -' },
      );
    ws.cell(currentLine, 21)
      .number(+item.quantity || 0)
      .style(otherSubItemStyle);
    ws.cell(currentLine, 22)
      .number(item.sum ? +item.sum : 0)
      .style(otherSubItemStyle)
      .style({ numberFormat: '#,##0.00; -#,##0.00; -' });
    if (!item.percent) {
      ws.cell(currentLine, 23)
        .formula(
          item.reference?.includes('pix-receivement-below-')
            ? `IF($E${currentLine}=1;(U${currentLine}*B16+V${currentLine}*A16)/(1-F20/100);U${currentLine}*B16+V${currentLine}*A16)`
            : `IF($E${currentLine}=1;(U${currentLine}*T${currentLine}/(1-(F20/100)));(U${currentLine}*T${currentLine}))`,
        )
        .style(otherSubItemStyleRight)
        .style({ numberFormat: '#,##0.00; -#,##0.00; -' });
    } else {
      ws.cell(currentLine, 23)
        .formula(
          `IF($E${currentLine}=1;(T${currentLine}*V${currentLine})/(1-F20/100);T${currentLine}*V${currentLine})`,
        )
        .style(otherSubItemStyleRight)
        .style({ numberFormat: '#,##0.00; -#,##0.00; -' });
    }
    currentLine += 1;
  }
  sheetData.financial.length <= 0 && (currentLine += 1);

  ws.cell(currentLine, 3).string('Total').style(totalSubItemStyle);
  ws.cell(currentLine, 4).style(totalSubItemStyle);
  ws.cell(currentLine, 5).style(totalSubItemStyle);
  ws.cell(currentLine, 6).style(totalSubItemStyle);

  ws.cell(currentLine, 20).style(totalSubItemStyleRight);
  ws.cell(currentLine, 21).style(totalSubItemStyleRight);
  ws.cell(currentLine, 22).style(totalSubItemStyleRight);
  ws.cell(currentLine, 23)
    .formula(`SUM(W${inicioServicosFinanceiros + 3}:W${currentLine - 1})`)
    .style(totalSubItemStyleRight)
    .style({ numberFormat: '#,##0.00; -#,##0.00; -' });

  const inicioMensalidades = currentLine + 2;

  if (sheetData.plans.length) {
    // //mensalidades
    ws.cell(inicioMensalidades, 3).string('Mensalidades').style(headersStyle);
    ws.cell(inicioMensalidades, 4).style(headersStyle);
    ws.cell(inicioMensalidades, 5).style(headersStyle);
    ws.cell(inicioMensalidades, 6).style(headersStyle);

    ws.cell(inicioMensalidades, 20).style(headersStyle);
    ws.cell(inicioMensalidades, 21).style(headersStyle);
    ws.cell(inicioMensalidades, 22).style(headersStyle);
    ws.cell(inicioMensalidades, 23).style(headersStyle);

    ws.row(inicioMensalidades + 1).setHeight(5);

    ws.cell(inicioMensalidades + 2, 3)
      .string('Item')
      .style(itemStyle);
    ws.cell(inicioMensalidades + 2, 4)
      .string('Métrica')
      .style(othersStyle);
    ws.cell(inicioMensalidades + 2, 5)
      .string('Imposto pós')
      .style(othersStyle);
    ws.cell(inicioMensalidades + 2, 6).style(othersStyle);

    ws.cell(inicioMensalidades + 2, 20)
      .string('Preço')
      .style(othersStyle);
    ws.cell(inicioMensalidades + 2, 21)
      .string('Quantidade')
      .style(othersStyle);
    ws.cell(inicioMensalidades + 2, 22)
      .string('Valor')
      .style(othersStyle);
    ws.cell(inicioMensalidades + 2, 23)
      .string('Total (em R$)')
      .style(othersStyle);

    currentLine = inicioMensalidades + 3;
    for (let i = 0; i < sheetData.plans.length; i += 1) {
      const item = sheetData.plans[i];
      // items da seção
      ws.cell(currentLine, 3).string(item.description).style(subItemStyle);
      ws.cell(currentLine, 4).string(item.metric).style(otherSubItemStyle);
      ws.cell(currentLine, 5)
        .number(item.taxes_amount ? 1 : 0)
        .style(otherSubItemStyle)
        .style({ numberFormat: '"Sim";;"Não"' });
      ws.cell(currentLine, 6).style(otherSubItemStyle);

      ws.cell(currentLine, 20)
        .number(+item.amount || 0)
        .style(otherSubItemStyle)
        .style(
          item.percent
            ? { numberFormat: '#0.00%; -#0.00%; -' }
            : { numberFormat: '#,##0.00; -#,##0.00; -' },
        );
      ws.cell(currentLine, 21)
        .number(+item.quantity || 0)
        .style(otherSubItemStyle);
      ws.cell(currentLine, 22)
        .number(0)
        .style(otherSubItemStyleRight)
        .style({ numberFormat: '#,##0.00; -#,##0.00; -' });
      ws.cell(currentLine, 23)
        .formula(
          `IF($E${currentLine}=1;(U${currentLine}*T${currentLine}/(1-(F20/100)));(U${currentLine}*T${currentLine}))`,
        )
        .style(otherSubItemStyleRight)
        .style({ numberFormat: '#,##0.00; -#,##0.00; -' });

      currentLine += 1;
    }

    ws.cell(currentLine, 3).string('Total').style(totalSubItemStyle);
    ws.cell(currentLine, 4).style(totalSubItemStyle);
    ws.cell(currentLine, 5).style(totalSubItemStyle);
    ws.cell(currentLine, 6).style(totalSubItemStyle);

    ws.cell(currentLine, 20).style(totalSubItemStyleRight);
    ws.cell(currentLine, 21).style(totalSubItemStyleRight);
    ws.cell(currentLine, 22).style(totalSubItemStyleRight);
    ws.cell(currentLine, 23)
      .formula(`SUM(W${inicioMensalidades + 3}:W${currentLine - 1})`)
      .style(totalSubItemStyleRight)
      .style({ numberFormat: '#,##0.00; -#,##0.00; -' });
  }

  const inicioSomas = currentLine + 2;

  ws.cell(inicioSomas, 3)
    .string('Serviços Operacionais')
    .style(subItemStyle)
    .style(faturamentoFillStyle);
  ws.cell(inicioSomas, 4).style(otherSubItemStyle).style(faturamentoFillStyle);
  ws.cell(inicioSomas, 5).style(otherSubItemStyle).style(faturamentoFillStyle);
  ws.cell(inicioSomas, 6).style(otherSubItemStyle).style(faturamentoFillStyle);
  ws.cell(inicioSomas, 7).style(faturamentoFillStyle);
  ws.cell(inicioSomas, 19).style(faturamentoFillStyle);
  ws.cell(inicioSomas, 20).style(otherSubItemStyle).style(faturamentoFillStyle);
  ws.cell(inicioSomas, 21).style(otherSubItemStyle).style(faturamentoFillStyle);
  ws.cell(inicioSomas, 22).style(otherSubItemStyle).style(faturamentoFillStyle);
  ws.cell(inicioSomas, 23)
    .formula(`W${inicioServicosFinanceiros - 2}`)
    .style(otherSubItemStyleRight)
    .style(faturamentoFillStyle)
    .style({ numberFormat: '#,##0.00; -#,##0.00; -' });

  ws.cell(inicioSomas + 1, 3)
    .string('Serviços Financeiros')
    .style(subItemStyle)
    .style(faturamentoFillStyle);
  ws.cell(inicioSomas + 1, 4)
    .style(otherSubItemStyle)
    .style(faturamentoFillStyle);
  ws.cell(inicioSomas + 1, 5)
    .style(otherSubItemStyle)
    .style(faturamentoFillStyle);
  ws.cell(inicioSomas + 1, 6)
    .style(otherSubItemStyle)
    .style(faturamentoFillStyle);
  ws.cell(inicioSomas + 1, 7).style(faturamentoFillStyle);
  ws.cell(inicioSomas + 1, 19).style(faturamentoFillStyle);
  ws.cell(inicioSomas + 1, 20)
    .style(otherSubItemStyle)
    .style(faturamentoFillStyle);
  ws.cell(inicioSomas + 1, 21)
    .style(otherSubItemStyle)
    .style(faturamentoFillStyle);
  ws.cell(inicioSomas + 1, 22)
    .style(otherSubItemStyle)
    .style(faturamentoFillStyle);
  ws.cell(inicioSomas + 1, 23)
    .formula(`W${inicioMensalidades - 2}`)
    .style(otherSubItemStyleRight)
    .style(faturamentoFillStyle)
    .style({ numberFormat: '#,##0.00; -#,##0.00; -' });

  let inicioResultados = inicioSomas + 3;

  if (sheetData.plans.length) {
    ws.cell(inicioSomas + 2, 3)
      .string('Mensalidades')
      .style(subItemStyle)
      .style(faturamentoFillStyle);
    ws.cell(inicioSomas + 2, 4)
      .style(otherSubItemStyle)
      .style(faturamentoFillStyle);
    ws.cell(inicioSomas + 2, 5)
      .style(otherSubItemStyle)
      .style(faturamentoFillStyle);
    ws.cell(inicioSomas + 2, 6)
      .style(otherSubItemStyle)
      .style(faturamentoFillStyle);
    ws.cell(inicioSomas + 2, 7).style(faturamentoFillStyle);
    ws.cell(inicioSomas + 2, 19).style(faturamentoFillStyle);
    ws.cell(inicioSomas + 2, 20)
      .style(otherSubItemStyle)
      .style(faturamentoFillStyle);
    ws.cell(inicioSomas + 2, 21)
      .style(otherSubItemStyle)
      .style(faturamentoFillStyle);
    ws.cell(inicioSomas + 2, 22)
      .number(0)
      .style(otherSubItemStyleRight)
      .style(faturamentoFillStyle)
      .style({ numberFormat: '#,##0.00; -#,##0.00; -' });
    ws.cell(inicioSomas + 2, 23)
      .formula(`W${inicioSomas - 2}`)
      .style(otherSubItemStyleRight)
      .style(faturamentoFillStyle)
      .style({ numberFormat: '#,##0.00; -#,##0.00; -' });

    inicioResultados = inicioSomas + 4;
  }

  ws.cell(inicioResultados, 3)
    .string('Resultado')
    .style(totalSubItemStyle)
    .style({
      font: {
        name: 'arial',
        bold: true,
        size: 14,
        underline: false,
      },
    });
  ws.cell(inicioResultados, 4).style(totalSubItemStyle);
  ws.cell(inicioResultados, 5).style(totalSubItemStyle);
  ws.cell(inicioResultados, 6).style(totalSubItemStyle);

  ws.cell(inicioResultados, 20).style(totalSubItemStyleRight);
  ws.cell(inicioResultados, 21).style(totalSubItemStyleRight);
  ws.cell(inicioResultados, 22).style(totalSubItemStyleRight);
  ws.cell(inicioResultados, 23)
    .formula(`SUM(W${inicioSomas}:W${inicioResultados - 2})`)
    .style(totalSubItemStyleRight)
    .style({
      font: {
        name: 'arial',
        bold: true,
        size: 14,
      },
    })
    .style({ numberFormat: '#,##0.00; -#,##0.00; -' });

  const inicioDetalhesFinanceiros = inicioResultados + 2;

  ws.cell(inicioDetalhesFinanceiros, 3)
    .string('Detalhes Financeiros')
    .style(headersStyle);
  ws.cell(inicioDetalhesFinanceiros, 4).style(headersStyle);
  ws.cell(inicioDetalhesFinanceiros, 5).style(headersStyle);
  ws.cell(inicioDetalhesFinanceiros, 6).style(headersStyle);

  ws.cell(inicioDetalhesFinanceiros, 20).style(headersStyle);
  ws.cell(inicioDetalhesFinanceiros, 21).style(headersStyle);
  ws.cell(inicioDetalhesFinanceiros, 22).style(headersStyle);
  ws.cell(inicioDetalhesFinanceiros, 23).style(headersStyle);

  ws.row(inicioDetalhesFinanceiros + 1).setHeight(5);

  const inicioItensDetalhesFinanceiros = inicioDetalhesFinanceiros + 2;

  const totalRealTime = (): number => {
    const CLIENTS_TO_IGNORE_REAL_TIME = [
      '838cb5cd-5f83-4a69-a01d-724493481307', // Galera
      '3581b3b1-188b-4ff7-aab4-761471a05f9a', // Sorte
      '4f6bd29d-9dca-4aac-b3da-967fbc306fd4', // Urano
      '2885af00-1efb-4a3c-93ff-db9a0b8beb91', // FDS
      '95086e36-cac4-4aee-9f1c-90465c52de65', // DIRECTA24
      'f264f837-ee3f-4429-86aa-06e840efcc22', // NUVEI,
      '5a26ed72-666b-4609-9e0e-fa680cc965bf', // VCTRADE
    ];

    let res = 0;
    if (!CLIENTS_TO_IGNORE_REAL_TIME.includes(client.id)) {
      sheetData.financial.forEach((item: any) => {
        res += +item.amount * +item.quantity;
      });
    }
    return res;
  };

  ws.cell(inicioItensDetalhesFinanceiros, 3)
    .string('Cobrança em tempo real  (Serviços financeiros)')
    .style(subItemStyle)
    .style(faturamentoFillStyle);
  ws.cell(inicioItensDetalhesFinanceiros, 4)
    .style(otherSubItemStyle)
    .style(faturamentoFillStyle);
  ws.cell(inicioItensDetalhesFinanceiros, 5)
    .style(otherSubItemStyle)
    .style(faturamentoFillStyle);
  ws.cell(inicioItensDetalhesFinanceiros, 6)
    .style(otherSubItemStyle)
    .style(faturamentoFillStyle);
  ws.cell(inicioItensDetalhesFinanceiros, 7).style(faturamentoFillStyle);
  ws.cell(inicioItensDetalhesFinanceiros, 19).style(faturamentoFillStyle);
  ws.cell(inicioItensDetalhesFinanceiros, 20)
    .style(otherSubItemStyle)
    .style(faturamentoFillStyle);
  ws.cell(inicioItensDetalhesFinanceiros, 21)
    .style(otherSubItemStyle)
    .style(faturamentoFillStyle);
  ws.cell(inicioItensDetalhesFinanceiros, 22)
    .style(otherSubItemStyle)
    .style(faturamentoFillStyle);
  ws.cell(inicioItensDetalhesFinanceiros, 23)
    .number(totalRealTime())
    .style(otherSubItemStyleRight)
    .style(faturamentoFillStyle)
    .style({ numberFormat: '#,##0.00; -#,##0.00; -' });

  ws.cell(inicioItensDetalhesFinanceiros + 1, 3)
    .string('Cobrança pós (demais taxas)')
    .style(subItemStyle)
    .style(faturamentoFillStyle);
  ws.cell(inicioItensDetalhesFinanceiros + 1, 4)
    .style(otherSubItemStyle)
    .style(faturamentoFillStyle);
  ws.cell(inicioItensDetalhesFinanceiros + 1, 5)
    .style(otherSubItemStyle)
    .style(faturamentoFillStyle);
  ws.cell(inicioItensDetalhesFinanceiros + 1, 6)
    .style(otherSubItemStyle)
    .style(faturamentoFillStyle);
  ws.cell(inicioItensDetalhesFinanceiros + 1, 7).style(faturamentoFillStyle);
  ws.cell(inicioItensDetalhesFinanceiros + 1, 19).style(faturamentoFillStyle);
  ws.cell(inicioItensDetalhesFinanceiros + 1, 20)
    .style(otherSubItemStyle)
    .style(faturamentoFillStyle);
  ws.cell(inicioItensDetalhesFinanceiros + 1, 21)
    .style(otherSubItemStyle)
    .style(faturamentoFillStyle);
  ws.cell(inicioItensDetalhesFinanceiros + 1, 22)
    .style(otherSubItemStyle)
    .style(faturamentoFillStyle);
  ws.cell(inicioItensDetalhesFinanceiros + 1, 23)
    .formula(`W${inicioResultados}-W${inicioItensDetalhesFinanceiros}`)
    .style(otherSubItemStyleRight)
    .style(faturamentoFillStyle)
    .style({ numberFormat: '#,##0.00; -#,##0.00; -' });

  // ws.cell(inicioItensDetalhesFinanceiros + 2, 3)
  //   .string('Receitas')
  //   .style(subItemStyle)
  //   .style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 2, 4)
  //   .style(otherSubItemStyle)
  //   .style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 2, 5)
  //   .style(otherSubItemStyle)
  //   .style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 2, 6)
  //   .style(otherSubItemStyle)
  //   .style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 2, 7).style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 2, 19).style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 2, 20)
  //   .style(otherSubItemStyle)
  //   .style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 2, 21)
  //   .style(otherSubItemStyle)
  //   .style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 2, 22)
  //   .number(+sheetData.revenuesToDiscount - +(sheetData.revenuesToQesh || 0))
  //   .style(otherSubItemStyleRight)
  //   .style(faturamentoFillStyle)
  //   .style({ numberFormat: '#,##0.00; -#,##0.00; -' });

  // ws.cell(inicioItensDetalhesFinanceiros + 3, 3)
  //   .string('Valor pendente')
  //   .style(subItemStyle)
  //   .style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 3, 4)
  //   .style(otherSubItemStyle)
  //   .style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 3, 5)
  //   .style(otherSubItemStyle)
  //   .style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 3, 6)
  //   .style(otherSubItemStyle)
  //   .style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 3, 7).style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 3, 19).style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 3, 20)
  //   .style(otherSubItemStyle)
  //   .style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 3, 21)
  //   .style(otherSubItemStyle)
  //   .style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 3, 22)
  //   .formula(
  //     `=V${inicioItensDetalhesFinanceiros + 1}-V${
  //       inicioItensDetalhesFinanceiros + 2
  //     }`,
  //   )
  //   .style(otherSubItemStyleRight)
  //   .style(faturamentoFillStyle)
  //   .style({ numberFormat: '#,##0.00; -#,##0.00; -' })
  //   .style({
  //     font: {
  //       name: 'arial',
  //       bold: true,
  //       size: 14,
  //     },
  //   });

  // ws.cell(inicioItensDetalhesFinanceiros + 2, 3)
  //   .string('Valor de nota')
  //   .style(subItemStyle)
  //   .style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 2, 4)
  //   .style(otherSubItemStyle)
  //   .style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 2, 5)
  //   .style(otherSubItemStyle)
  //   .style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 2, 6)
  //   .style(otherSubItemStyle)
  //   .style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 2, 7).style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 2, 19).style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 2, 20)
  //   .style(otherSubItemStyle)
  //   .style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 2, 21)
  //   .style(otherSubItemStyle)
  //   .style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 2, 22)
  //   .formula(`=V${inicioResultados}*(1-(F20*100)%)`)
  //   .style(otherSubItemStyleRight)
  //   .style(faturamentoFillStyle)
  //   .style({ numberFormat: '#,##0.00; -#,##0.00; -' });

  // ws.cell(inicioItensDetalhesFinanceiros + 3, 3)
  //   .string('Impostos adicionais')
  //   .style(subItemStyle)
  //   .style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 3, 4)
  //   .style(otherSubItemStyle)
  //   .style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 3, 5)
  //   .style(otherSubItemStyle)
  //   .style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 3, 6)
  //   .style(otherSubItemStyle)
  //   .style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 3, 7).style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 3, 19).style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 3, 20)
  //   .style(otherSubItemStyle)
  //   .style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 3, 21)
  //   .style(otherSubItemStyle)
  //   .style(faturamentoFillStyle);
  // ws.cell(inicioItensDetalhesFinanceiros + 3, 22)
  //   .formula(`=V${inicioResultados}-V${inicioItensDetalhesFinanceiros + 2}`)
  //   .style(otherSubItemStyleRight)
  //   .style(faturamentoFillStyle)
  //   .style({ numberFormat: '#,##0.00; -#,##0.00; -' });

  const excelBuffer = await wb.writeToBuffer();
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, `Custo_${client.name}_${month}.xlsx`);
}
