/* eslint-disable no-await-in-loop */
/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect } from 'react';
import { Button, notification, Select, Spin } from 'antd';

import { addMonths, startOfDay, startOfMonth, subSeconds } from 'date-fns';
import { endOfMonth } from 'date-fns/esm';
import api from '../../../services/api';
import bank_api from '../../../services/bank_api';

import { create as createCosts } from './costTemplate';
import { create as createRevenues } from './revenuesTemplate';

interface IClient {
  id: string;
  name: string;
  bill_cost: number;
  ted_cost: number;
  tef_cost: number;
}

interface ICost {
  amount: string | number;
  created_at: string;
  description: string;
  end_date: string | null;
  id: string;
  metric: string;
  percent: boolean;
  quantity: string | number;
  reference: string | null;
  start_date: string | null;
  taxes_amount: string | number | null;
  type: string;
}

const taxaImposto = 18.35;

const Reports: React.FC<{ bank: any }> = ({ bank }) => {
  const [loadingData, setLoadingData] = useState(false);
  const [costsData, setCostsData] = useState<ICost[] | null>(null);

  const [months] = useState([
    'JANEIRO',
    'FEVEREIRO',
    'MARÇO',
    'ABRIL',
    'MAIO',
    'JUNHO',
    'JULHO',
    'AGOSTO',
    'SETEMBRO',
    'OUTUBRO',
    'NOVEMBRO',
    'DEZEMBRO',
  ]);
  const [selectedMonth, setSelectedMonth] = useState<string | null>('');
  const [years] = useState([2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028]);
  const [selectedYear, setSelectedYear] = useState(2022);
  const [selectedDate, setSelectedDate] = useState({
    start: startOfMonth(new Date()).toISOString(),
    end: endOfMonth(new Date()).toISOString(),
  });

  const loadCountData = useCallback(
    async (client: IClient, start: string, end: string) => {
      const token = localStorage.getItem('@BaasQesh:token');

      try {
        const { data } = await bank_api.get(
          `/admin/clients/analytics?start=${start}&end=${end}`,
          {
            headers: {
              admintoken: token,
              client: client.id,
            },
          },
        );

        const {
          data: {
            clients,
            tedsCount,
            tedsFeesSum,
            tefsCount,
            tefsFeesSum,
            paymentsCount,
            billetsCount,
            billetsFeesSum,
            activeAccountsCount,
            createdPFAccountsCount,
            createdPJAccountsCount,
            neowayPartnersSearchs,
            pixTransfersFeesSum,
            pixReceivementsCount,
            pixQrCodesCount,
            pixReceivementAmount,
            pixTransfersAmount,
            pixTransfersCount,
            pixReceivementsBelowAmount,
            pixReceivementsBelowCount,
            pixReceivementsAboveAmount,
            pixReceivementsAboveCount,
          },
        } = await api.get('/admin/accounts/reports', {
          params: {
            start,
            end,
            'pix-below-amount': 100,
            'pix-above-amount': 100,
          },
          headers: {
            client: client.id,
          },
        });

        const neowayPartnersSearchsCount =
          JSON.stringify(neowayPartnersSearchs).split('document').length - 1;

        const {
          sms: smsCount,
          email: emailCount,
          bigData: bigDataCount,
          lifeness: lifenessCount,
        } = data;

        return {
          smsCount,
          emailCount,
          bigDataCount,
          lifenessCount,
          tedsCount,
          tedsFeesSum,
          tefsFeesSum,
          billetsFeesSum,
          tefsCount,
          billetsCount,
          paymentsCount,
          activeAccountsCount,
          createdPFAccountsCount,
          createdPJAccountsCount,
          neowayPartnersSearchsCount,
          pixTransfersCount,
          pixTransfersFeesSum,
          pixReceivementsCount,
          pixQrCodesCount,
          pixTransfersAmount,
          pixReceivementAmount,
          pixReceivementsBelowAmount,
          pixReceivementsBelowCount,
          pixReceivementsAboveAmount,
          pixReceivementsAboveCount,
        };
      } catch (err) {
        console.log(err);
        notification.error({
          message: 'Erro ao buscar informações (BANK_API).',
        });
      }
      return [];
    },
    [],
  );

  const handleOptionChange = useCallback(
    async (start, end): Promise<void> => {
      // const client = clients?.find(i => i.id === opt);
      if (bank) {
        setLoadingData(true);
        setCostsData(null);
        // setSelectedClient(client);

        try {
          const { data: costs } = await bank_api.get(
            `/admin/clients/costs?start=${start}&end=${end}`,
            {
              headers: {
                admintoken: localStorage.getItem('@BaasQesh:token'),
                client: bank.id,
              },
            },
          );

          const {
            smsCount,
            emailCount,
            bigDataCount,
            lifenessCount,
            tedsCount,
            tedsFeesSum,
            tefsFeesSum,
            billetsFeesSum,
            tefsCount,
            billetsCount,
            paymentsCount,
            activeAccountsCount,
            createdPFAccountsCount,
            createdPJAccountsCount,
            neowayPartnersSearchsCount,
            pixTransfersCount,
            pixReceivementsCount,
            pixTransfersAmount,
            pixReceivementAmount,
            pixQrCodesCount,
            pixReceivementsBelowAmount,
            pixReceivementsBelowCount,
            pixReceivementsAboveAmount,
            pixReceivementsAboveCount,
          }: any = await loadCountData(bank, start, end);

          const tedCostIndex = costs.findIndex(
            (item: ICost) => item.reference === 'ted',
          );
          const tefCostIndex = costs.findIndex(
            (item: ICost) => item.reference === 'tef',
          );
          const billetCostIndex = costs.findIndex(
            (item: ICost) => item.reference === 'billet',
          );
          const emailCostIndex = costs.findIndex(
            (item: ICost) => item.reference === 'email',
          );
          const smsCostIndex = costs.findIndex(
            (item: ICost) => item.reference === 'sms',
          );
          const createdPJCostIndex = costs.findIndex(
            (item: ICost) => item.reference === 'pj-created',
          );
          const createdPFCostIndex = costs.findIndex(
            (item: ICost) => item.reference === 'pf-created',
          );
          const activeCostIndex = costs.findIndex(
            (item: ICost) => item.reference === 'active',
          );
          const bigDataCostIndex = costs.findIndex(
            (item: ICost) => item.reference === 'bigdata',
          );
          const antiFraudCostIndex = costs.findIndex(
            (item: ICost) => item.reference === 'anti-fraud',
          );
          const neowayPartnersSearchsIndex = costs.findIndex(
            (item: ICost) => item.reference === 'partners-search',
          );

          const pixTransfersCostIndex = costs.findIndex(
            (item: ICost) => item.reference === 'pix',
          );

          const pixReceivementsCostIndex = costs.findIndex(
            (item: ICost) => item.reference === 'pix-receivement',
          );

          const pixReceivementsBelowCostIndex = costs.findIndex(
            (item: ICost) => item.reference === 'pix-receivement-below-100',
          );

          const pixReceivementsAboveCostIndex = costs.findIndex(
            (item: ICost) => item.reference === 'pix-receivement-above-100',
          );

          const pixQrCodesCostIndex = costs.findIndex(
            (item: ICost) => item.reference === 'pix-qrcode',
          );

          const pixVolumetryIndex = costs.findIndex(
            (item: ICost) => item.reference === 'pix-volumetry',
          );

          const lifenessIndex = costs.findIndex(
            (item: ICost) => item.reference === 'saffe',
          );

          if (tedCostIndex !== -1) costs[tedCostIndex].quantity = tedsCount;
          if (tefCostIndex !== -1) costs[tefCostIndex].quantity = tefsCount;
          if (billetCostIndex !== -1)
            costs[billetCostIndex].quantity = billetsCount;
          if (smsCostIndex !== -1) costs[smsCostIndex].quantity = smsCount;
          if (lifenessIndex !== -1)
            costs[lifenessIndex].quantity = lifenessCount;
          if (emailCostIndex !== -1)
            costs[emailCostIndex].quantity = emailCount;
          if (createdPJCostIndex !== -1)
            costs[createdPJCostIndex].quantity = createdPJAccountsCount;
          if (createdPFCostIndex !== -1)
            costs[createdPFCostIndex].quantity = createdPFAccountsCount;
          if (activeCostIndex !== -1)
            costs[activeCostIndex].quantity = activeAccountsCount;
          if (bigDataCostIndex !== -1)
            costs[bigDataCostIndex].quantity = bigDataCount;
          if (antiFraudCostIndex !== -1)
            costs[antiFraudCostIndex].quantity =
              +tedsCount + +tefsCount + +paymentsCount;
          if (neowayPartnersSearchsIndex !== -1)
            costs[
              neowayPartnersSearchsIndex
            ].quantity = neowayPartnersSearchsCount;

          if (pixTransfersCostIndex !== -1) {
            costs[pixTransfersCostIndex].quantity = pixTransfersCount;
            costs[pixTransfersCostIndex].sum = pixTransfersAmount;
          }

          if (pixReceivementsCostIndex !== -1) {
            costs[pixReceivementsCostIndex].quantity = pixReceivementsCount;
            costs[pixReceivementsCostIndex].sum = pixReceivementAmount;
          }

          if (pixReceivementsBelowCostIndex !== -1) {
            costs[
              pixReceivementsBelowCostIndex
            ].quantity = pixReceivementsBelowCount;
            costs[
              pixReceivementsBelowCostIndex
            ].sum = pixReceivementsBelowAmount;
          }

          if (pixReceivementsAboveCostIndex !== -1) {
            costs[
              pixReceivementsAboveCostIndex
            ].quantity = pixReceivementsAboveCount;
            costs[
              pixReceivementsAboveCostIndex
            ].sum = pixReceivementsAboveAmount;
          }

          if (pixQrCodesCostIndex !== -1)
            costs[pixQrCodesCostIndex].quantity = pixQrCodesCount;

          const paymentRevenueIndex = costs.findIndex(
            (item: ICost) => item.reference === 'payment-revenue',
          );
          const tedFeesRevenueIndex = costs.findIndex(
            (item: ICost) => item.reference === 'ted-revenue',
          );
          const tefFeesRevenueIndex = costs.findIndex(
            (item: ICost) => item.reference === 'tef-revenue',
          );
          const billetFeesRevenueIndex = costs.findIndex(
            (item: ICost) => item.reference === 'billet-revenue',
          );

          if (paymentRevenueIndex !== -1) {
            costs[paymentRevenueIndex].quantity = paymentsCount;
          }
          if (tedFeesRevenueIndex !== -1) {
            costs[tedFeesRevenueIndex].quantity = tedsCount;
            costs[tedFeesRevenueIndex].total = tedsFeesSum;
          }
          if (tefFeesRevenueIndex !== -1) {
            costs[tefFeesRevenueIndex].quantity = tefsCount;
            costs[tefFeesRevenueIndex].total = tefsFeesSum;
          }
          if (billetFeesRevenueIndex !== -1) {
            costs[billetFeesRevenueIndex].quantity = billetsCount;
            costs[billetFeesRevenueIndex].total = billetsFeesSum;
          }

          const apiUtilizationIndex = costs.findIndex(
            (item: ICost) => item.reference === 'api-utilization',
          );

          if (apiUtilizationIndex !== -1) {
            let apiUtilizations = 0;

            costs.forEach((currentCost: ICost) => {
              if (!currentCost.type.includes('REVENUE'))
                apiUtilizations += +currentCost.quantity || 0;
            });

            costs[apiUtilizationIndex].quantity = apiUtilizations;
          }

          if (pixVolumetryIndex !== -1) {
            if (pixReceivementAmount < 50_000_000) {
              costs[pixVolumetryIndex].amount = 0.006;
            } else if (pixReceivementAmount < 100_000_000) {
              costs[pixVolumetryIndex].amount = 0.005;
            } else if (pixReceivementAmount < 200_000_000) {
              costs[pixVolumetryIndex].amount = 0.004;
            } else {
              costs[pixVolumetryIndex].amount = 0.003;
            }

            costs[pixVolumetryIndex].quantity =
              +pixReceivementsCount + +pixTransfersCount;

            costs[pixVolumetryIndex].sum =
              +pixTransfersAmount + +pixReceivementAmount;
          }

          setCostsData(costs);
          setLoadingData(false);
        } catch (err) {
          console.log(err);
        }
      }
    },
    [bank, loadCountData],
  );

  useEffect(() => {
    setCostsData(null);
    if (!loadingData) {
      handleOptionChange(selectedDate.start, selectedDate.end);
    }
  }, [selectedDate, bank]);

  const handleChangeMonth = useCallback(
    OPT => {
      const month = months.findIndex(i => i === OPT) + 1;

      setSelectedMonth(months[month - 1]);
      const dateStart = startOfDay(
        new Date(`${selectedYear}/${month > 9 ? month : `0${month}`}/01`),
      );

      const start = dateStart.toISOString();

      const dateEnd = subSeconds(
        startOfDay(addMonths(dateStart, 1)),
        1,
      ).toISOString();

      setSelectedDate({ start, end: dateEnd });

      handleOptionChange(start, dateEnd);
    },
    [months, selectedYear, handleOptionChange],
  );

  const handleChangeYear = useCallback(
    OPT => {
      const year = years.findIndex(i => i === OPT);
      setSelectedYear(years[year]);
    },
    [years],
  );

  const handleGenerateSheet = useCallback(async () => {
    if (bank) {
      try {
        // const {
        //   data,
        // }: {
        //   data: {
        //     teds: number;
        //     tefs: number;
        //     billets: number;
        //     pixTransfers: number;
        //     pixQrCodes: number;
        //     payments: number;
        //     phoneTopups: number;
        //     total: number;
        //   };
        // } = await api.get(
        //   `/admin/dashboard/balances/revenues?start=${startDate}&end=${endDate}`,
        //   {
        //     headers: {
        //       client: bank.id,
        //     },
        //   },
        // );

        // const revenuesPercentage = costsData?.find(
        //   (item: ICost) => item.type === 'REVENUES_PERCENT',
        // );

        // let revenuesToQesh;

        /// porcentagem da receita
        // if (revenuesPercentage) {
        //   revenuesToQesh = +revenuesPercentage.amount * +data.total;
        // }

        // console.log('createCosts');
        createCosts(
          {
            operational: costsData
              ?.filter((item: ICost) => item.type === 'OPERATIONAL')
              .sort((a: ICost, b: ICost) =>
                a.description > b.description
                  ? 1
                  : b.description > a.description
                  ? -1
                  : 0,
              ),
            financial: costsData
              ?.filter((item: ICost) => item.type === 'FINANCIAL')
              .sort((a: ICost, b: ICost) =>
                a.description > b.description
                  ? 1
                  : b.description > a.description
                  ? -1
                  : 0,
              ),
            plans: costsData
              ?.filter((item: ICost) => item.type === 'PLANS')
              .sort((a: ICost, b: ICost) =>
                a.description > b.description
                  ? 1
                  : b.description > a.description
                  ? -1
                  : 0,
              ),
            // revenuesToDiscount: +data.total,
            // revenuesToQesh,
          },
          {
            client: bank,
            month: selectedMonth,
            year: selectedYear,
            taxaImposto,
          },
        );

        // galera
        if (bank.id === '838cb5cd-5f83-4a69-a01d-724493481307') {
          createRevenues(
            {
              operational: costsData
                ?.filter((item: ICost) => item.type === 'OPERATIONAL_REVENUE')
                .sort((a: ICost, b: ICost) =>
                  a.description > b.description
                    ? 1
                    : b.description > a.description
                    ? -1
                    : 0,
                ),
              financial: costsData
                ?.filter((item: ICost) => item.type === 'FINANCIAL_REVENUE')
                .sort((a: ICost, b: ICost) =>
                  a.description > b.description
                    ? 1
                    : b.description > a.description
                    ? -1
                    : 0,
                ),
              plans: costsData
                ?.filter((item: ICost) => item.type === 'PLANS_REVENUE')
                .sort((a: ICost, b: ICost) =>
                  a.description > b.description
                    ? 1
                    : b.description > a.description
                    ? -1
                    : 0,
                ),
              percent: costsData
                ?.filter((item: ICost) => item.type === 'REVENUES_PERCENT')
                .sort((a: ICost, b: ICost) =>
                  a.description > b.description
                    ? 1
                    : b.description > a.description
                    ? -1
                    : 0,
                ),
            },
            {
              client: bank,
              month: selectedMonth,
              year: selectedYear,
            },
          );
        }
      } catch (e) {
        notification.error({
          message: 'Ocorreu um erro ao gerar o relatório de custos.',
        });
      }
    }
  }, [bank, selectedMonth, costsData, selectedYear /* startDate, endDate */]);

  return (
    <>
      {bank && (
        <>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: '100%', textAlign: 'center' }}>
              <h1 style={{ margin: 10 }}>Relatório de transações</h1>
              <Select
                defaultValue={selectedYear}
                disabled={loadingData}
                style={{ width: 200, alignSelf: 'center', margin: 20 }}
                options={years.map(i => ({ label: i, value: i }))}
                onChange={handleChangeYear}
              />
              <Select
                defaultValue=""
                disabled={loadingData}
                style={{ width: 200, alignSelf: 'center', margin: 20 }}
                options={months.map(i => ({ label: i, value: i }))}
                onChange={handleChangeMonth}
              />
              {loadingData && <Spin size="small" />}
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  disabled={loadingData || !costsData}
                  onClick={handleGenerateSheet}
                >
                  Exportar
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Reports;
